import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';

@Pipe({ name: 'formGroupType' })

export class FormGroupTypePipe implements PipeTransform {
  transform(value: AbstractControl): UntypedFormGroup {
    return value as UntypedFormGroup
  }
}
