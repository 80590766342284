import { AbstractControl, UntypedFormArray } from '@angular/forms';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formArrayType'
})
export class FormArrayTypePipe implements PipeTransform {

  transform(value: AbstractControl): UntypedFormArray {
    return value as UntypedFormArray;
  }

}
