import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormGroupTypePipe } from './form-group-type.pipe';
import { FormArrayTypePipe } from './form-array-type.pipe';
import { DayjsPipe } from './dayjs.pipe';
import { FormControlTypePipe } from './form-control-type.pipe';


@NgModule({
  declarations: [
    DayjsPipe,
    FormGroupTypePipe,
    FormArrayTypePipe,
    FormControlTypePipe
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    DayjsPipe,
    FormGroupTypePipe,
    FormArrayTypePipe,
    FormControlTypePipe
  ]
})
export class PipesModule { }
